import React, { useState } from 'react';

const RandomGroupGenerator = () => {
  const [maxNumber, setMaxNumber] = useState(100);
  const [numGroups, setNumGroups] = useState(5);
  const [groups, setGroups] = useState([]);

  const handleMaxNumberChange = (e) => {
    setMaxNumber(Number(e.target.value));
  };

  const handleNumGroupsChange = (e) => {
    setNumGroups(Number(e.target.value));
  };

  const generateGroups = () => {
    const numbers = [];
    while (numbers.length < maxNumber) {
      const randNum = Math.floor(Math.random() * maxNumber) + 1;
      if (!numbers.includes(randNum)) {
        numbers.push(randNum);
      }
    }

    const newGroups = [];
    const groupSize = Math.floor(maxNumber / numGroups);
    
    for (let i = 0; i < numGroups; i++) {
      newGroups.push(numbers.splice(0, groupSize));
    }

    if (numbers.length > 0) {
      numbers.forEach((number, index) => {
        newGroups[index].push(number);
      });
    }

    setGroups(newGroups);
  };

  // Inline Styles
  const styles = {
    container: {
      maxWidth: '600px',
      margin: '0 auto',
      padding: '20px',
      textAlign: 'center',
      backgroundColor: '#f7f7f7',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: '#333',
    },
    input: {
      padding: '8px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      width: '60%',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    groupContainer: {
      marginTop: '30px',
      textAlign: 'left',
    },
    group: {
      marginBottom: '20px',
    },
    groupTitle: {
      fontSize: '18px',
      color: '#007bff',
    },
    groupList: {
      listStyleType: 'none',
      paddingLeft: '0',
    },
    listItem: {
      padding: '5px 0',
      fontSize: '16px',
    },
  };

  return (
    <div style={styles.container}>
      <h1>Random Group Generator</h1>
      <div style={styles.inputContainer}>
        <label style={styles.label}>
          Max Number:
          <input 
            type="number" 
            value={maxNumber} 
            onChange={handleMaxNumberChange} 
            style={styles.input}
          />
        </label>
      </div>
      <div style={styles.inputContainer}>
        <label style={styles.label}>
          Number of Groups:
          <input 
            type="number" 
            value={numGroups} 
            onChange={handleNumGroupsChange} 
            style={styles.input}
          />
        </label>
      </div>
      <button onClick={generateGroups} style={styles.button}>
        Generate Groups
      </button>

      <div style={styles.groupContainer}>
        <h2>Generated Groups</h2>
        {groups.length > 0 && groups.map((group, index) => (
          <div key={index} style={styles.group}>
            <h3 style={styles.groupTitle}>Group {index + 1}</h3>
            <ul style={styles.groupList}>
              {group.map((num, i) => <li key={i} style={styles.listItem}>{num}</li>)}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RandomGroupGenerator;
